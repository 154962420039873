






























































  import { Vue, Component, Prop } from 'vue-property-decorator';
  import CusTable from "@/components/table";
  import MixinEdu from "@/views/education/Mixins/mixin-edu";
  import axios from "@/core/axios";

  @Component({
    mixins: [ MixinEdu ]
  })
  export default class extends Vue{
	  constructor() {
      super();
    }
    private data = []
    private AddDialogVisible = false
    private inputVal = ''
    private rowId = ''
	  private initAreaData = []
	  private param = {
      name: '',
      provinceId: '',
      cityId: '',
      areaId: ''
	  }
	  private areaVal: string[] = [];
	  private addAreaVal: string[] = [];
	  private addAreaPlaceholder: string[] = ['请选择地区']
    private props = {
      checkStrictly: true,
      label: 'name',
      children: 'childs',
	    value: 'id',
      lazy: true,
      lazyLoad: (node, resolve) => {
        const { id } = node.root ? '' : node.data;
        node.root ? this.getArea('') : this.getArea(id, resolve)
      }
    }
    $refs!: {table: CusTable, addCascader: any}

    private areaChange() {
	    [this.param.provinceId, this.param.cityId, this.param.areaId] = this.areaVal
	    this.$refs.table.request(this.param);
    }

    private async getArea(parentId?, resolve?) {
      const res: any = await this.axios.post('/system/area/queryByParentId', {parentId});
      if (res.result && !resolve) this.initAreaData = res.json;
      if (resolve) resolve(res.json)
    }

    private async addQuesSource() {
      if (this.inputVal === '') {
        this.$message.warning('请输入校区名称')
        return false
      };
      if (this.addAreaVal.length === 0) {
        this.$message.warning('请选择地区')
        return false;
      }
      let data = { provinceId: '', cityId: '', areaId: '', provinceName: '', cityName: '', areaName: ''};
      if (this.AddDialogVisible && this.rowId === '') {
        const node = this.$refs.addCascader.getCheckedNodes()[0];
        if (node.isLeaf) {
          [data.provinceId, data.cityId, data.areaId] = node.path;
          [data.provinceName, data.cityName, data.areaName] = node.pathLabels
        }
      } else if ( this.AddDialogVisible && this.rowId !== '') {
        [data.provinceId, data.cityId, data.areaId] = this.addAreaVal;
        [data.provinceName, data.cityName, data.areaName] = this.addAreaPlaceholder
      };
      const res: any = await this.axios.post('/admin/publicSchool/saveOrUpdate', {id: this.rowId, ...data, name: this.inputVal});
      if (res.result) this.AddDialogVisible = !this.AddDialogVisible, this.$refs.table.request();
    }

    private selectionChange(ev) {
      this.selectedArr = ev;
    };

	  private timers:number = 0;
	  private searchInputChange() {
      clearTimeout(this.timers);
      setTimeout(() => {
        this.$refs.table.request(this.param)
      }, 600)
    }

    private handleEdit(index, row) {
      this.AddDialogVisible = true;
      this.addAreaVal = [row.provinceId, row.cityId, row.areaId]
	    this.addAreaPlaceholder = [row.provinceName, row.cityName, row.areaName]
      this.rowId = row.id;
      this.inputVal = row.name;
    }

    /**
     * 停用 && 启用
     */
    private handleChangeStatus(idx, row: never | any) {
      this.selectedArr = [row]
      this.batchChangeStatus((row.status == 1 ? 0 : 1), this.$refs.table,'/admin/publicSchool/batchChangeStatus')
    }

    handleDelete(idx, row) {
      this.selectedArr = [row]
      this.batchDeleteHandle(this.$refs.table, '/admin/publicSchool/batchDelete');
    }

    private batchChangeStatusHandle(status) {
      this.batchChangeStatus(status, this.$refs.table, '/admin/publicSchool/batchChangeStatus');
    };
  }
